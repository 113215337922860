@import url('https://font.googleapis.com/css2?family=Lato&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  margin: 0;
  padding: 0px;
  font-family: 'Nunito', sans-serif;
}

