
html, body{
    margin: 0;
    padding: 0;
    border: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;

   background-color: #fff!important;
}
#msg-msg-problemas{
    position: fixed;
    bottom: 10px;
    margin-left: 10px;
    width: 99%;
    z-index: 99999999999999;
}
#div-banner-home{
overflow: hidden!important;
background-color: #fff!important;    

    
}
.input-login{
    background-color: #444;
    padding-left: 10px!important;
}
.img-banner-home{
    width:   100%!important;
    overflow: hidden!important;
    background-color: #fff!important;
    
}
.banner-home-overlay{
    width: 100%;
    font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif!important;
    position: absolute;
    top: 10px;
    left:0px;
    height: 100%;

 
    text-align: left;
    color: #fff;
 

    text-align: center;
    text-shadow: 0px 0px 50px #000;
    overflow: hidden!important;
}

.banner-home-overlay h2{
    font-size: 3.5rem!important;
}
.banner-home-overlay h5{
    font-size: 0.5rem!important;
}

#cards-atuacao-main{

    left: 0px;
    width: 100%;
    background-color: #fff!important;
    z-index: 3!important;
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.card-atuacao-item{
    width: 30%;
    padding:15px;
    text-align: left;
    background-color: rgba(229,229,238,0.3);
 
    margin-top: 50px;

    border-radius: 10px;

}

.card-atuacao-item svg{
   font-size: 2.5rem;
   
   color: rgba(150,150,144,0.9);
}
.card-atuacao-item h5{
    font-size: 1.4rem;
}
.card-atuacao-item  p{
    font-size: 0.9rem!important;
    text-align: left;
    padding:5px
}
#nav-principal-main{
    display: flex;
    height: 50px!important;
    background-color: transparent!important;
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
    z-index: 10;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10px;
    padding-bottom: 10px;
}


#nav-principal-main svg{
    color: rgba(80, 80, 80, 0.5)!important;
   
    
}

#nav-brand{
    padding-top: 5px;
    width: 15%;
 
    
    
}
#nav-brand span{
    color: #fff!important;
    font-weight: bold;
    
    
}
#nav-menu  {
    display: flex;
    justify-content: space-around;
    width: 45%;
    padding-top: 5px;
   
}

#nav-menu span {
    color:#fff!important;
}

#nav-menu span:hover {
    color: #f00!important;
    cursor: pointer;
}
#nav-social{
    width: 40%;
    padding-left: 20px;
    align-content: center;
    display: flex;
    justify-content: space-around;
    color:#fff!important;
}
#nav-social svg{
    color:#fff!important;
}
#nav-social div{
   
  
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 50px;
}
#nav-social div:hover{
    
   
    cursor: pointer;
}
a{
    color:#444!important;
}

.div-img-card{
    background-color: red;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    display: flex;
    justify-items: baseline;
    align-items: center;
    flex-direction: column;
    align-content: center;
}

#label-banner{
    position: absolute;
    top:200px;
    left: 0px;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
}
.div-carrosel-marcas{
    border: solid 1 px red;
    padding-left: 15%;
    padding-right: 15%;
    height: 250px!important;
}
#cards-atuacao-main{
    margin-top: -180px!important;
   
}

#div-outsourcing-main{
    margin-top: 150px;
    padding-left: 15%;
    padding-right: 15%;
    text-align: center;
  
    width: 100%;
    height: 60vh;
    
}
#div-termicas{
    display: flex;
    flex-wrap: wrap;
   justify-content: center;
   
  
}

.image {
    position: relative;   
    width: 25%;
    overflow: hidden;
    margin-left: 0px;
    
    

  }
  .image img {
    max-width: 100%;
    max-height: 100%;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding:10px;
    text-align: center;
    opacity: 0;
    z-index: 200;
    display: flex;
    flex-direction: column;
    align-content: space-around;
  }
  .image:hover .overlay{
    opacity: 100;
    background-color: rgba(229,0,0,0.5);
    
    cursor: pointer;
  }
  .overlay:hover *{
    color: #fff!important;
  }
  .image:hover img{
    opacity: 0.5!important;
    -moz-transform: scale(1.3);
	-webkit-transform: scale(1.3);
    transform: scale(1.3);
    
    
  }

  .icon-aspas{
      width: 52px;
  }
  .div-img-overlay-card img{
      width: 80%;
  }

  #container-3d-flex{
    display: flex;
    
    padding-left: 15%;
    padding-right: 15%;
  }
  #container-3d-text{
      width: 60%;
  }
  #container-3d-img{
    width: 40%;
    padding:20px
}
#container-3d-img img{
    width: 100%;

   
    
}
#div-pre-footer{
    width: 100%;
    text-align: center;
    padding-top: 120px;
    padding-bottom: 120px;
    padding-left: 25%;
    padding-right: 25%;
    
}
#div-pre-footer #form{
    background-color: #fff!important;
  
    border-radius: 10px;
    padding-bottom: 50px;
    width: 100%;
    padding:20px
}
  @keyframes go-back {
    0%,100%{
      
          transform:translatey(0);
          transform:translatex(0)
      }
      50%{
      
          transform:translatey(-30px);
          transform:translatex(-25)
      }
  }
  .efeito-flutuante{
    animation: go-back 5s infinite;
  }
  #footer{
      padding-top: 40px;
      height: 390px;
      background-color: #444;
      padding-left: 15%;
      padding-right: 15%;
        color : #fff;
  }
  #footer img{
      width: 25%;
  }
  #footer svg{
    color:#777!important;
    width: 32px;
    height: 32px;
    margin-top: 10px;
  }
  #footer svg:hover{
    color:#fff!important;
    cursor: pointer;
  }

  #footer-logo{
      display: flex;
      justify-content: space-between;
  }
  #footer hr{
      background-color: #777;
  }
  #logo-whats{
      position: fixed;
      bottom: 25px;
      right: 25px;
      width: 90px;
      height: 90px;

      
  }
  #logo-whats:hover{
      transform: scale(1.2);
  }
  #logo-whats img{
      width: 100%;
      cursor: pointer;
  }
  button:hover{
      opacity: 0.7;
  }
  #div-fale-especialista{
      position: fixed;
      top:40%;
      right: 0px;
      width: 150px;
      color:#fff;
      background-color: #b00020;
      border-radius: 5px 0px 0px 5px;
      padding: 5px;
      z-index: 99999999;
      cursor: pointer;
      display: flex;
    }
    #div-fale-icon{
        width: 20%;
    }
    #div-fale-text{
        width: 80%;
    }
    #div-fale-especialista:hover{
        transform: scale(1.2);
    }