html, body{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
      background-color: #efefef!important;
}
.fade-in {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
  }
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }


.navbar-light{
    background-color: #fff!important;
}

.nav-link{
  
}
.nav-link:hover{
   text-shadow: 0px 0px 10px #ccc;
   border-radius: 2px;
   
   
}
#container-hone {
    background-color: #f8f9fa;
}

.div-card-marca{
    background-color: red;
}

@media (max-width: 480px) { 
    .navbar-brand{
        width: 70%!important;
    }
    .navbar-brand img{
        width: 40%!important;
    }

    #banner-principal-container-text{
        font-size: 1.1rem;
        padding: 20px;
        text-align: center;
    }
    #banner-principal-container-imagem{
        font-size: 1.1rem; 
    }
    #page-3d-container{
        background-color: #fff;
        padding: 10%;
        padding-top: 100px;
        min-height: 100vh;
        padding:30px;
    }
    .banner-principal-container-text{
        font-size: 0.9rem;
        padding-top: 0px;
        margin-top: 0px;
        padding-left: 10px;
        padding-right: 0px;
        text-align: center;
    }
    .banner-principal-container-text h3{
        font-size: 1.4rem;  
       
    }
}

/* Telefones em paisagem a tablet em retrato */
@media (max-width: 767px) {
    .navbar-brand{
        width: 70%!important;
    }
    .navbar-brand img{
        width: 40%!important;
    }
}

/* Tablet em retrato a paisagem e desktop */
@media (min-width: 768px)  {
    .navbar-brand{
        width: 50%!important;
    }
    .navbar-brand img{
        width: 40%!important;
    }
}

/* Desktop grande */
@media (min-width: 1200px) {
    .div-mav-container{
        padding-left: 9%;
        padding-right: 9%;
    }
    .navbar-brand{
 
        width: 50%!important;
        text-align: left!important;
 
    }
    .navbar-brand img{
        width: 30%!important;
    }


    .section-bannerprincipal-main{
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 70px;
        display: flex;
        background-color: #fff;
        min-height: 90vh;
        
    }
    .banner-principal-container-text{
        width: 45%;
    }
    .banner-principal-container-text h3{
        font-size: 2.5rem;  
       
    }
    .banner-principal-container-text h6{
        font-size: 1.2rem;  
       
    }
    .banner-principal-container-imagem{
        width: 55%; 
    }
    .banner-principal-container-imagem img{
        width: 100%!important;
    }
    .section-bannerprincipal-overlay{
        background-color: red;
        height: 180px;
        position: absolute;
        top: 80vh!important;
        right: 0px;
        width: 70%;
        z-index: 99999;
    }
    #div-palarax{
        background-color: #fff!important;
    }
    .div-palarax-container{
        display: flex;
        justify-content: center;
    }
    #div-palarax .carousel-control-prev{
        background-color: red!important;
        width: 60px;
        height: 60px;
        padding: 10px!important;
        top: 70vh!important;
        position: absolute;
        left: 80%;
    }

    #div-palarax .carousel-control-next{
        background-color: red!important;
        width: 60px;
        height: 60px;
        padding: 10px!important;
        top: 70vh!important;
        position: absolute;
        right: 10%;
    }

    #page-3d-container{
        background-color: #fff;
        padding: 10%;
        padding-top: 100px;
        min-height: 100vh;
    }
    .page-container-banner1{
        display: flex;
    }
    .div-menu-float{
    
      
        position: absolute;
        background-color: #fff;
        box-shadow: 0px 0px 10px #666;
        border-radius: 5px;
        display: none;
        padding: 10px;
        margin-top: 10px;
    }
    .menu-link{
        height: 45px;
    }

    .menu-link:hover  .div-menu-float{
        display: block;
        
    }
    .div-menu-float svg{
        font-size: 32px!important;
    }
    .link-item{
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .link-item:hover{
        background-color: red;
        border-radius: 5px;
        color: #fff;
        
       
    }

    .cards-solucoes-main{
        display: flex;
        justify-content: space-around;
        padding-left: 5%;
        padding-right: 5%;
    }
    .cards-solucoes-main div{
   
        min-height: 50vh;
        width: 23%;
        border-radius: 10px;
        text-align: center;
        padding: 10px;
        background-color: #fff;
      
     
    }
    .cards-solucoes-main div svg{
        font-size: 6.5rem;
        color:#b71c1c;
        opacity: 0.5;
    }
   
}


@media (min-width: 1367px) {
    .div-mav-container{
        padding-left: 9%;
        padding-right: 9%;
    }
    .navbar-brand{
 
        width: 50%!important;
        text-align: left!important;
 
    }
    .navbar-brand img{
        width: 30%!important;
    }


    .section-bannerprincipal-main{
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 70px;
        display: flex;
        background-color: #fff;
        min-height: 90vh;
        
    }
    .banner-principal-container-text{
        width: 45%;
    }
    .banner-principal-container-text h3{
        font-size: 3.5rem;  
       
    }
    .banner-principal-container-text h6{
        font-size: 1.7rem;  
       
    }
    .banner-principal-container-imagem{
        width: 55%; 
    }
    .banner-principal-container-imagem img{
        width: 100%!important;
    }
    .section-bannerprincipal-overlay{
        background-color: red;
        height: 180px;
        position: absolute;
        top: 80vh!important;
        right: 0px;
        width: 70%;
        z-index: 99999;
    }
    #div-palarax{
        background-color: #fff!important;
    }
    .div-palarax-container{
        display: flex;
        justify-content: center;
    }
    #div-palarax .carousel-control-prev{
        background-color: red!important;
        width: 60px;
        height: 60px;
        padding: 10px!important;
        top: 70vh!important;
        position: absolute;
        left: 80%;
    }

    #div-palarax .carousel-control-next{
        background-color: red!important;
        width: 60px;
        height: 60px;
        padding: 10px!important;
        top: 70vh!important;
        position: absolute;
        right: 10%;
    }

    #page-3d-container{
        background-color: #fff;
        padding: 10%;
        padding-top: 100px;
        min-height: 100vh;
    }
    .page-container-banner1{
        display: flex;
    }
   
}