body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background-color: #fff!important;
    color:#444!important;
}
.display-flex{
  display: flex;
  width: 100%;
  align-items:center;
  align-content: space-around;
  justify-items: center;
  justify-content: space-around;
  
}
.main-container-chamadoQR{
  background-color: #f6f6f6;

  height: 100vh;
}
@keyframes radarAnimation {
  0% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: rotate(360deg) scale(1.5);
    opacity: 0;
  }
}

.button-sucess{
  border: solid 0.5px #004D40!important;
  color: #004D40!important;
  background-color: #fff!important;
}
.button-sucess:hover{
  border: solid 0.5px #004D40!important;
  color: #fff!important;
  background-color: #004D40!important;
  box-shadow: 0px 0px 5px #004D40!important;
}


.radar-element {
  width: 20px;
  height: 20px;
  background-color: red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  animation: radarAnimation 3s linear infinite;
}
  .container-videos-main{

    width: 100%;
    height: 78vh;
    display: flex;
  }    
  .container-videos-main-play{

    width: 70%;
    height: 100%;
   
  }   
  .container-videos-main-miniaturas{
 
    width: 30%;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    text-align: center;
    align-content: space-evenly;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  } 
  .container-videos-main-miniaturas-item{
    padding: 5%;
    padding-top: 10px!important;
    background-color: #f5f5f5;
    border: solid 1px red;
    width: 26%;
    align-content: center;
    justify-content: center;
    margin-left: 10px;
    align-items: center;
    height: 120px;
  }  
.button-success{
  color:#00695C;
  border-color: #00695C;
}
.text-link{
  color:#1A237E!important;
}

.text-link:hover{
  color:#1A237E!important;
  font-weight: bold;
  cursor: pointer;
}

.container-chamado-qrcode{
  
  padding-bottom: 0px;
  width: 100%;
  min-height: 90vh;
  background-image: linear-gradient(to top,#fff,#fff, #000, #444);
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  
}
.container-chamado-qrcode-overlay{
  background-color: #fff;
  padding: 30px;
  border-radius: 30px 30px 0px 0px;
  min-height: 60vh;


}
#banner-qrcode{

  border-radius: 5px;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 50px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main{
  padding: 10px ;
  padding: 0px;
  padding-left: 70px;
  overflow-x: hidden!important;
  color:#444!important;

}
p{
  color:#444!important; 
}

.corpoBtnDetalhesPedido{

  padding-right:  30px !important;
  width: 50% !important;
}

.BtnDetalhesEquipamentosAlinhamentos{
  display: flex;
  margin-bottom: 10px;

}

.BtnDetalhesEquipamentosItens{
  display: flex;
  margin-top: 10px;
  margin-bottom: 26px;
  margin-right: 50px;
}

.BtnDetalhesEquipamentosDivTitulos{
  width: 300px;
}

.BtnDetalhesEquipamentosDivTextFields{
  width: 600px;
}

#TypographyNomeSolicitaToner{
  margin-top: 10px;
}

#textFieldNomeSolicitaToner{
  width: 338px !important;
}

#textFieldEmailSilicitaToner{
  width: 500px !important;
}

#textFieldObservacaoSolicitaToner{
  width: 880px;
  height: 50px;
  text-align: justify;
}

.BtnDetalhesChamadoModalCabecalho{
  display: flex;
}

.BrnPermissoesUsuariosPrincipalDialog{
  display: flex;
}

.areaResponsiveContainerDivPrincipal{
  width: 33%;
}

.simpleRadialBarChartDivPrincipal{
  width: 33%;
}

#areaResponsiveContainerTitulo{
  text-align: center;
  margin-bottom: 30px;
  text-align: center;
  font-size: 1vw;
}

#simpleRadialBarChartTitulo{
  text-align: center;
  margin-bottom: 30px;
  text-align: center;
  font-size: 1vw;
}

.simpleLineChartDivPrincipal{
  width: 33%;
}

#simpleLineChartTitulo{
  text-align: center;
  margin-bottom: 30px;
  text-align: center;
  font-size: 1vw;
}

.SimpleLineChartFonteLinha{
  font-family: Verdana !important;
  font-weight: bold;
  font-size: x-small;
}

.areaResponsiveContainerFonteLinha{
  font-family: Verdana !important;
  font-weight: bold;
  font-size: x-small;
}

.SimpleRadialBarChartFonteLinha{
  font-family: Verdana !important;
  font-weight: bold;
  font-size: x-small;
}

.dashboardDivCharts{
  display: flex;
}

.dashboardDivListaChamados{
  padding-top: 50px;
}

.ListasDeEquipamentosDoUsuario{
  text-align: center;
  padding-top: 50px;
  padding-bottom: 30px;
}

#BtnDetalhesChamadoModalImgLogo{
  padding: 10px;
}

#BtnDetalhesChamadoModalImgLogo img{
  width: 10vw;
}

#BtnDetalhesChamadoModalPrimeiraLinha{
  text-align:   right;
  padding-top: 20px;
}

#textFieldContadorMonoSolicitaToner{
  width:120px;
}

#textFieldContadorColorSilicitaToner{
  width:120px;
}

.StatusTextoPedidoChamado{
  font-size: 9px !important;
}

#customized-dialog-title{
  background-color: #37474f !important;
  color: white;
}

.MuiOutlinedInput-notchedOutline{

  padding-left: 10px!important;
 
}
#principa-toolbar-icons{
  height: 3,5vw;
  background-color: #fff;
  text-align: center;

}


#principa-toolbar-icons button{
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 60px;
  margin-right: 60px;
  
}

.main-content{
  padding-left: 5%!important;
  padding-right: 5%!important; 
}


.template-janela-padrao{
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;

}
#template-janela-main{
  width: 98%;
  padding: 5px;
  margin-top: 5vw;

}

#template-janela-header-topo{
  display: flex;
}
#template-janela-header-titulo{
  width: 40%;
}
#template-janela-header-paginacao{
  width: 60%;
}
#template-janela-container{
  margin-top: 50px;
  background-color: #fff;
  border-radius: 5px;
  padding: 0px;
  padding-top: 10px;
  z-index: 1;
  
}
#template-janela-header-tools{
margin-top: 40px;
}

.campoDePesquisaPedidos{
  margin-right: 30px !important;

}
.swal2-container{
  z-index: 9999999999999999999!important;
 
}

.MuiButton-outlined{
  border: dashed #cbcbcb 1px!important;
 
}

.borderButton{
  margin-right: 1px!important;
  border: solid 1px!important;
}

.btnSuccess{
 
  color:#00897b!important;
  margin-left: 1px!important;

}
.btnSuccess:hover{
  background-color:  #4db6ac!important;
  color:#fff!important;

  
}


.btnInfo{  
 
  color:#01579b!important;
  margin-left: 1px!important;

}
.btnInfo:hover{  
  background-color:  #29b6f6!important;
  color:#fff!important;
}


.btnWarning{  
 
  color:#f57c00!important;
  margin-left: 1px!important;

}
.btnWarning:hover{  
  background-color:  #ffd54f!important;
  color:#fff!important;
}


.btnError{
 
  color:#dd2c00!important;
  margin-left: 1px!important;

}
.btnError:hover{
  background-color:  #ff7043!important;
  color:#fff!important;
}
.iconError{
 
  color:#dd2c00!important;
  margin-left: 1px!important;

}

.btnDefault{
  font-size: small !important;
  color:#444!important;
  margin-left: 1px!important;
  width: 7vw !important;
  max-width: 14% !important;
}

.btnDefault:hover{
  background-color:  #9e9e9e!important;
  color:#fff!important;
}

.divisor{
  margin-top: 30px;
  margin-bottom: 30px;
  border: solid 1px #9e9e9e;
}

#span-sidebar{
  margin-left: 10px;
}

.floatingButton{
  position: fixed;
  right: 60px;
  bottom: 40px;
}

#rotuloCorPreto{
  background-color: black;
  color: white;
  font-weight: bold;
  width: 5vw;
  height: 1.5vw;
  padding-top: 5px;
  border-radius: 10px;
}

#rotuloCorCian{
  background-color: cyan;
  font-weight: bold;
  width: 5vw;
  height: 1.5vw;
  padding-top: 5px;
  border-radius: 10px;
}

#rotuloCorMagenta{
  background-color: magenta;
  font-weight: bold;
  width: 5vw;
  height: 1.5vw;
  padding-top: 5px;
  border-radius: 10px;
}

#rotuloCorAmarelo{
  background-color: yellow;
  font-weight: bold;
  width: 5vw;
  height: 1.5vw;
  padding-top: 5px;
  border-radius: 10px;
}

.placaDescricaoLinhaPedido{
  background-color: #343a40;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
}

.simple-menu-suprimentos{
  margin-top: 2.5vw !important;
}

.spnDefault{
  background-color:  #ccc!important;
  color:#000;
  padding:3px;
  border-radius: 5px;
  font-size: 10px;
}
.spnInfo{
  border: solid 1px  #283593!important;
  color:#283593;
  padding:4px;
  border-radius: 5px;
  font-size: 9px;
}

.spnSuccess{
  background-color:  #4db6ac!important;
  color:#fff;
  padding:4px;
  border-radius: 5px;
  font-size: 9px;
}
.spnWarning{
  background-color:  #f57c00!important;
  color:#fff;
  padding:4px;
  border-radius: 5px;
  font-size: 9px;
}
.spnError{
  background-color:  #dd2c00!important;
  color:#fff;
  padding:4px;
  border-radius: 5px;
  font-size: 9px;
}
.spanFullWidth{
  width: 100%;
}

.textLink{
  color : #1a237e!important;
}
.textLink:hover{
  color : #1a237e!important;
  text-decoration: underline;
  cursor: pointer;
}

.sidebar {
  background-color: #efefef !important;
  height: flex !important;
  justify-content: start !important;
  align-items: center !important;
}

.menu-bars {
  margin-left: 2rem !important;
  font-size: 2rem !important;
  background: none !important;
}

.nav-menu {
  margin-top: 65px;
  background-color: #37474f !important;
  width: 250px !important;
  height: 100vh !important;
  display: flex !important;
  justify-content: center !important;
  position: fixed !important;
  top: 0 !important;
 left: -100% !important;
  transition: 850ms !important;
}

.nav-menu.active {
  left: 0 !important;
  transition: 350ms !important;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a{
text-decoration: none;
color: #f5f5f5;
font-size: 18px;
width: 95%;
height: 100%;
display: flex;
align-items: center;
padding: 0 16px;
border-radius: 4px;
}

.nav-text a:hover{
  background-color: #6c757d;
  text-decoration: none;
  color: #f5f5f5;
}

.nav-menu-items {
  width: 100%;
  margin-top: 30px;
}

.navbar-toggle {

  background-color: #37474f !important;
  width: 100% !important;
  height: 40px !important;
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
}

@media print {


.areaImpressao{
  margin: 0;
}

}

.MuiFormControl-root Label{
  color:#444!important;
}

.divFlexSpacing{
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: space-between!important;
  width: 100%;
 
}

.input-search{
  background-color: #546E7A;
  border-bottom:  none!important;
  padding-left: 5px!important;
  padding-right: 5px!important;
  border-radius: 5px;
  
}
.input-search input{
  color:#fff!important;
}
.input-search:focus svg{
  color: orangered!important;
}
.side-header-brand{
  border: solid 1px red;
  height: 150px;
  text-align: center;
}
#nav-main-left{
  padding:8px;
  padding-top: 70px;
  background-image: linear-gradient(to left, transparent, #E8EAF6);
  color:#37474F!important;
  height: 100vh;
  width: 120px;
  position: fixed;
  top: 0px;
  left: 0px;
  padding-right: 5px;
  

}
#nav-main-left:hover{
 
  

}
#nav-main-left hr{
  background-image: linear-gradient(to right, transparent, #E8EAF6);
}


#nav-main-left .nav-main-top{

}
#nav-main-left .div-button-nav{
  width: 95%;

  border-radius: 5px;
  text-align: center;
   color:#37474F;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
 margin-top: 2px;
 margin-bottom: 2px;
  
}
.text-link-bold{
  color:#00f;
  font-weight: bold;
}

#nav-main-left .div-button-nav:hover{

  background-color: #fff!important;
  cursor: pointer;
  color:#444!important;
 

}


#nav-main-left .div-button-nav-sair{
  width: 98%;
  background-color: #FFCDD2;
  border-radius: 5px;
  text-align: center;
  color:#D50000;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
 margin-top: 2px;
 margin-bottom: 2px;
  
}
#nav-main-left .div-button-nav-sair:hover{
  background-color: #D50000;
  cursor: pointer;
  color: #fff!important;


}
#nav-main-left hr{
  width: 98%!important;
  
}
#nav-main-left .div-button-nav-disabled{
  width: 95%;

  border-radius: 5px;
  text-align: center;
color:#37474F;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
 margin-top: 2px;
 margin-bottom: 2px;

}
#nav-main-left .div-button-nav-disabled:hover{
  opacity: 0.4;
 cursor: not-allowed;
}



.rotulo-button-nav{
  font-size: 9px!important;
  margin-top: -15px!important;
  padding-top: 0px;
  
 
}



#div-main-container{

  padding:10px;
  padding-top: 40px;
  padding-right: 10px!important;
  margin-top: 60px;
  margin-left: 110px;



}
#div-main-container2{

  padding:10px;
  padding-top: 10px;
  padding-right: 10px!important;
  margin-top: 60px;
  margin-left: 10px;



}

.texto-limitado{
  width: 100%!important;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}

#nav-main-left .div-button-nav div{
  padding: 0px!important;
}


.flex-spacing{
  display: flex;
  justify-content: space-between;
}


#nav-main-left .div-button-nav-ativa{
  width: 95%;
  border: #37474F;
  border-radius: 5px;
  text-align: center;
  color:#fff;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
 margin-top: 5px;
 margin-bottom: 5px;
}
#nav-main-left .div-button-nav-ativa{
  width: 95%;
  background-color: #37474F;
  border-radius: 5px;
  text-align: center;
  color:#fff;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
 margin-top: 5px;
 margin-bottom: 5px;
}
/* botoes */


.button-default{
  background-color: #90A4AE!important;
  color:#fff!important;
  width: 100%!important;
  height: 100%!important;
  box-shadow: none!important;
  
}
.button-default-outlined{
  border: solid 1px #CFD8DC!important;
  color:#546E7A!important;
 
}
.button-default-outlined:hover{
  background-color: #90A4AE!important;
  color:#fff!important;

}
.button-primary{
  background-color: #2196F3!important;
  color:#fff!important;
  width: 100%!important;
  height: 100%!important;
}

.button-error{
  background-color: #EF5350!important;
  color:#fff!important;
  width: 100%!important;
  height: 100%!important;
}
.button-success{
  background-color: #00897b!important;
  color:#fff!important;
  width: 100%!important;
  height: 100%!important;
}
.button-error70{
  background-color: #EF5350!important;
  color:#fff!important;
  width: 100%!important;
  height: 60%!important;
}
.button-success70{
  background-color: #00897b!important;
  color:#fff!important;
  width: 100%!important;
  height: 60%!important;
}


/* fim dos botoes */

.progress-loading{
  position: fixed!important;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 999999999999999;
}

.MuiOutlinedInput-root fieldset{
  border:  solid 1px #ccc!important;
}
#div-login .MuiInputBase-input{
  color:#fff!important;
}
.nivel-toner-container{
  display: flex;
  justify-content: center;
}
.label-success{
  border: solid 1px #00897b;
  color:#00897b;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.label-error{
  border: solid 1px #dd2c00;
  color:#dd2c00;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.large-button{
  height: 60px!important;
}
.MuiTextField-root:focus-within fieldset{
  border: solid 2px #1A237E!important;
  color:#1A237E!important;
 
}
.MuiTextField-root:focus-within label{
  color:#1A237E!important;
}

.form-error  .MuiFormHelperText-contained {
  color:#f00!important;
}
.form-error  label{
  color:#f00!important;
}
.uploadMain{
  border: dashed 1px #ccc; 
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
}
.grid-file-upload{
  font-family: roboto;
  margin-top: 10px;
  min-height: 120px;
  border: dashed 1px #ccc;
  border-radius: 5px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  color:#444;

}
.grid-file-upload:hover{
  border: dashed 1px #444;
  
}

.lista-none{
  list-style-type: none;
  
}
.upload-details{
  padding: 10px;
}


.grid-file-upload .form-container{
  border: Solid 1px lime;
 

}
.grid-file-upload .form-container button{
  border: none;
  background-color: transparent;
}
#nav-social input{
  color:#fff!important;
}

.botaoAcoes{
  position: fixed!important;
  right: 50px;
  bottom: 50px;
  z-index: 999;
  text-align: center;
  
}
.display-placa{
  border: solid 1px #283593;
  background-color: #0D47A1;
  color:#fff;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}
.labelDefault{
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border: solid 1px #000;
  border-radius: 5PX;
  cursor: pointer;
}
.labelSucess{
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border: solid 1px #2E7D32;
  background-color:#4CAF50 ;
  color:#fff;
  cursor: pointer;
  border-radius: 5PX;
}

.labelPreSucess{
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border: solid 1px #2E7D32;
  cursor: pointer;
  color:#2E7D32;
  
  border-radius: 5PX;
}
.labelInfo{
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border: solid 1px #1565C0;
  border-radius: 5PX;
  color:#1565C0;
  cursor: pointer;
}

.labelDanger{
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border: solid 1px #000;
  border-radius: 5PX;
  cursor: pointer;
}

.laberlWarning{
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border: solid 1px #FF8F00;
  color:#FF8F00;
  border-radius: 5px;
  cursor: pointer;
}

.container-children{

  padding:10px;
  padding-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
}

#dash2 h6{
  font-size: 10px!important;
}

#dash2 span{
  font-size: 8px!important;
}
.container-cards-dashboard-dca{
  display:  flex;
  width:  100%;
  justify-content: space-around;
  padding-top: 10px;
}

.container-cards-dashboard-dca-item{
    width: 15%;
    height: 60px;
    border-radius: 5px;
    display: flex;
}
.recharts-layer{
  font-size: 10px!important;
}

.container-cards-dashboard-dca-item-icon{
  display: flex;
  align-items: center;
  width: 30%;
  padding: 2px;
}

.container-cards-dashboard-dca-item-area{
  text-align: center;
  width: 70%;
}

.container-cards-dashboard-dca-item svg{
  color:#fff;
  height: 44px;
  width: 44px;
}
.color-default{
  color:#444!important;
}

.gradiente-info{
  background-image: linear-gradient(to bottom,  #2196F3, #1A237E );
}

.gradiente-warning{
  background-image: linear-gradient(to bottom, #FFD54F, #E65100 );
}

.gradiente-error{
  background-image: linear-gradient(to bottom, #EF5350, #B71C1C);
}

.gradiente-success{
  background-image: linear-gradient(to bottom,  #26A69A, #004D40);
}

.gradiente-default{
  background-image: linear-gradient(to bottom,  #6f6f6f, #000);
}
.container-sub-cards{
  display: flex;
}
.no-border{
  border: none!important;
}


.container-sub-cards:hover .no-border{
 
}
.no-border:hover{
  
}
.container-dash-toner{
  display: flex;
  justify-content: space-around;
  align-content: space-around;
  flex-wrap: wrap;
}
.div-alert-toner-dash{
  margin-top: 1px;
  border-radius: 5px;
  display: flex;
  width: 180px;
  height: 60px;
  padding: 1px;
  padding-left: 5px;
  margin-left: 2px;

}
#divLinhas{
  word-spacing: normal!important;
}
#divLinhas div{
 
  padding: 0!important;
  height: 15px;
}
#divLinhas span{

  padding: 0!important;

}
.div-line{
  display:inline!important;

}
.div-alert-toner-dash-niveis{
  justify-content: space-around;
}

.rotulo-PRETO{
  background-color: #ECEFF1;
  border: solid 1.5px #000!important;
}

.rotulo-CIANO{
  background-color: #E1F5FE;
  border: solid 1.5px #00f!important;
}

.rotulo-MAGENTA {
  background-color: #FFEBEE;
  border:solid 1.5px magenta!important
}

.rotulo-AMARELO{
  background-color: #FFF8E1;
  border:solid 1.5px #FF8F00!important
}
.small-loading{
  width: 22px!important;
  height: 22px!important;
}
.size-small svg{
  height: 25px;
  width: 25px;
}
.fileContainer{
 
  box-shadow: none!important;
  padding: 0px!important;
 margin-top: 50px!important;
}
.uploadPictureContainer{
 border: none!important;
 background-color: transparent!important;
 box-shadow: none!important;
 width: 20%!important;
 margin-left: 5px!important;
 padding: 0px!important;
}
.deleteImage{
  font-size: 12px!important;
  width: 24px!important;
  height: 24px!important;
  display: flex;
  align-items: center;
  justify-content: center;
}